import api from '@apiInstance';

export default {
  add(data) {
    return api.post('refnums', data);
  },
  get(id) {
    return api.fetch('refnums/' + id);
  },
  list(params) {
    return api.fetch('/refnums', { params });
  },
  update(data) {
    return api.patch('refnums', data);
  },
  delete(id) {
    return api.remove('refnums', id);
  },
};
