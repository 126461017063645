<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              id="jobsGrid"
              title="Ticket Number"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onEdit(v)"
              @on-update-cancel="(v) => loadTicketNumbers()"
            >
              <template #columns>
                <DxColumn data-field="reference_key" caption="Code">
                  <DxLookup
                    :data-source="referenceKeys"
                    value-expr="value"
                    display-expr="name"
                  />
                </DxColumn>
                <DxColumn data-field="description" caption="Description" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="refFormObserver">
        <b-row>
          <b-col>
            <b-form-group label="Reference Key" label-for="v-reference_key">
              <v-select
                id="v-reference_key"
                label="name"
                v-model="formData.reference_key"
                :options="referenceKeys"
                :reduce="(referenceKey) => referenceKey.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Preffix" label-for="v-prefix">
              <b-form-input v-model="formData.prefix" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Suffix" label-for="v-suffix">
              <b-form-input v-model="formData.suffix" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Next Value" label-for="v-next_value">
              <b-form-input v-model.number="formData.next_value" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Series Length" label-for="v-series_length">
              <b-form-input v-model.number="formData.series_length" />
            </b-form-group>
          </b-col>
          <!-- <b-col>
            <b-form-group label="Series Reset" label-for="v-series_reset">
              <b-form-input v-model="formData.series_reset" />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Description">
              <b-form-textarea v-model="formData.description" />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import refnumApi from '@api/refnums';
import { between } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'TicketNumbersPage',
  components: {},
  data: () => ({
    between,
    onShowModal: false,
    dataSource: [],
    referenceKeys: [
      { name: 'Order', value: 'ORDER' },
      { name: 'RFQ', value: 'RFQ' },
      { name: 'Quotation', value: 'QO' },
      { name: 'Proforma', value: 'PROFORMA' },
      { name: 'Cart', value: 'CART' },
      { name: 'Service Request', value: 'SRQ' },
      { name: 'Job Order', value: 'JO' },
      { name: 'Promo', value: 'PROMO' },
      { name: 'Sales Order', value: 'SO' },
      { name: 'Delivery Order', value: 'DO' },
      { name: 'Sales Invoice', value: 'SI' },
      { name: 'Stock In/Out', value: 'STOCK' },
      { name: 'Supplier In/Out', value: 'SUPPLIER' },
      { name: 'Jobs', value: 'JOBS' },
      { name: 'Incidents', value: 'CINCIDENT' },
      { name: 'Contracts', value: 'CONTRACTS' },
      { name: 'Customer Feedback', value: 'FB' },
      { name: 'Staff Feedback', value: 'SFB' },
      { name: 'Courses', value: 'TR' },
      { name: 'Enrollment', value: 'TM' },
      { name: 'Claims', value: 'CLAIMS' },
      { name: 'Renewal Refnum', value: 'R-REFNUM' },
      { name: 'Job Relocation Refnum', value: 'JREL-REFNUM' },
    ],
    formData: {
      id: 0,
    },
  }),
  mounted() {
    this.loadTicketNumbers();
  },
  methods: {
    loadTicketNumbers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      refnumApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const onSuccess = () => {
        this.loadTicketNumbers();
        this.onCloseModal();
        this.toastConfig();
      };

      const formData = this.formData;

      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          if (!formData.id) {
            refnumApi
              .add(formData)
              .then(({ data }) => {
                onSuccess();
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });

            return;
          }

          refnumApi
            .update(formData)
            .then(({ data }) => {
              onSuccess();
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });

          return;
        }

        this.toastConfig('', 'error');
      });
    },
    onEdit(event) {
      this.onShowModal = true;

      if (event.name === 'edit') {
        this.formData = event.data;
      }
    },
    onDelete(event) {
      refnumApi
        .delete(event)
        .then(({ data }) => {
          this.loadTicketNumbers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {
        id: 0,
      };
    },
  },
};
</script>

<style></style>
